import React, { Component } from "react";

export default class Home extends Component {
    render() {
        return (
            <div className="Home">
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-7 my-auto">
                                <div className="header-content mx-auto">
                                    <h1 className="mb-5">Find and watch opera performances. Anywhere. Any time.</h1>
                                    <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Click here to download now!</a>
                                </div>
                            </div>
                            <div className="col-lg-5 my-auto">
                                <div className="device-container">
                                    <div className="device-mockup iphone6_plus portrait white">
                                        <div className="device">
                                            <div className="screen">
                                                <img src="https://www.operadar.app/img/screenshot.png" className="img-fluid" alt="" />
                                            </div>
                                            {/* <div className="button">
                      <!-- You can hook the "home button" to some JavaScript events or just remove it -->
                    </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="features" id="features">
                    <div className="container">
                        <div className="section-heading text-center">
                            <h2>Love opera?</h2>
                            <p className="text-muted">Get tickets to your favourite performances...and more!</p>
                            <hr />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 my-auto">
                                <div className="device-container">
                                    <div className="device-mockup iphone6_plus portrait white">
                                        <div className="device">
                                            <div className="screen">
                                                {/* <!-- Demo image for screen mockup, you can put an image here, some HTML, an animation, video, or anything else! --> */}
                                                <img src="https://www.operadar.app/img/screenshot.png" className="img-fluid" alt="" />
                                            </div>
                                            {/* <div className="button">
                          <!-- You can hook the "home button" to some JavaScript events or just remove it -->
                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <h3>All the best performances...all under one roof.</h3>
                                                <p className="text-muted">The Search feature lets you find any performance being played around the world. Just type in the opera title, composer or company name!</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <h3>Want to know what’s on near you?</h3>
                                                <p className="text-muted">Use the Near feature to find performances near you - you never know what is being played around the corner.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <h3>Find that hidden gem</h3>
                                                <p className="text-muted">The Rarities feature shows the lesser known performances being played - be the one in ‘the know’ and find something rare.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="feature-item">
                                                <h3>Be the first watch Premieres</h3>
                                                <p className="text-muted">Book tickets for the hot premieres that no one wants to miss!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cta" id="cta">
                    <div className="cta-content">
                        <div className="container">
                            <div className="section-heading text-center">
                                <h2>Join the audience.<br />Anywhere.</h2>
                                <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">Click here to start now.</a>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </section>

                <section className="download bg-primary text-center" id="download">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <h2 className="section-heading">OpeRadar</h2>
                                <p>Our app is available on any mobile device! Download now to get started!</p>
                                <div className="badges">
                                    <a className="badge-link" target="blank" href="https://itunes.apple.com/app/id1320612169"><img src="https://www.operadar.app/img/app-store-badge.svg" alt="" /></a>
                                    <a className="badge-link" target="blank" href="https://play.google.com/store/apps/details?id=de.operadar.app"><img src="https://www.operadar.app/img/google-play-badge.svg" alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact" id="contact">
                    <div className="container">
                        <h2>Contact</h2>
                        <ul className="list-inline list-social">
                            <li className="list-inline-item social-facebook">
                                <a href="https://www.facebook.com/operadar">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li className="list-inline-item social-twitter">
                                <a href="https://twitter.com/OpeRadar">
                                    <i className="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li className="list-inline-item social-instagram">
                                <a href="https://www.instagram.com/operadar">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li className="list-inline-item social-mail">
                                <a href="mailto:support@operadar.app">
                                    <i className="fas fa-envelope"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        );
    }
}
