import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

/**
 * Wrapper class for text input element.
 */
const TextInput = (props) => {
  const { label, name, value, explanation, onChange, placeholder } = props;
  const explanationID = name + "Explanation";
  return (
    <p>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        aria-describedby={explanationID}
      />
      <Form.Text id={explanationID} className="text">
        {explanation}
      </Form.Text>
    </p>
  );
};

export default class AmazonLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      street: "",
      city: "",
      zipcode: "",
      country: "",
      mail: "",
      companyURL: "",
      phoneNumbers: "",
      twitterScreenName: "",
      facebookScreenName: "",
      instagramScreenName: "",
      message: "",
    };
  }

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "company", ...this.state }),
    })
      .then(() => alert("Success!"))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const {
      title,
      street,
      city,
      zipcode,
      country,
      mail,
      companyURL,
      phoneNumbers,
      twitterScreenName,
      facebookScreenName,
      instagramScreenName,
      message,
    } = this.state;

    return (
      <div className="DataInput">
        <section className="download bg-primary text-center" id="features">
          <div className="container h-100">
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="formName">
                <TextInput
                  label="Company name"
                  placeholder="Enter the company name"
                  explanation="How do you want the name of your company be written in the app."
                  name="title"
                  value={title}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formAddress">
                <TextInput
                  label="Street"
                  placeholder="Enter the street with house number if needed"
                  name="street"
                  value={street}
                  onChange={this.handleChange}
                />
                <Form.Row>
                  <Form.Group as={Col} sm controlId="formGridCity">
                    <TextInput
                      label="City"
                      placeholder="Enter the city"
                      name="city"
                      value={city}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm controlId="formGridZipcode">
                    <TextInput
                      label="Zipcode"
                      placeholder="Enter the zipcode"
                      name="zipcode"
                      value={zipcode}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm controlId="formGridCountry">
                    <TextInput
                      label="Country"
                      placeholder="Enter the country"
                      name="country"
                      value={country}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} sm controlId="formGridEmail">
                  <p>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter an email address"
                      name="mail"
                      value={mail}
                      onChange={this.handleChange}
                    />
                  </p>
                </Form.Group>
                <Form.Group as={Col} sm controlId="formGridWebsite">
                  <TextInput
                    label="Website"
                    placeholder="Enter a website link for tickets"
                    name="companyURL"
                    value={companyURL}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} sm controlId="formGridPhone">
                  <TextInput
                    label="Phone"
                    placeholder="Enter ticket phone line"
                    name="phoneNumbers"
                    value={phoneNumbers}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm controlId="formGridFacebook">
                  <TextInput
                    label="Facebook name"
                    placeholder="Enter Facebook name"
                    name="facebookScreenName"
                    value={facebookScreenName}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} sm controlId="formGridTwitter">
                  <TextInput
                    label="Twitter name"
                    placeholder="Enter Twitter name"
                    name="twitterScreenName"
                    value={twitterScreenName}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} sm controlId="formGridInstagram">
                  <TextInput
                    label="Instagram name"
                    placeholder="Enter Instagram name"
                    name="instagramScreenName"
                    value={instagramScreenName}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <p>
                  <Form.Label>
                    Is there anything else you want us to include:
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={message}
                    rows={3}
                    onChange={this.handleChange}
                  />
                </p>
              </Form.Group>
              <Button size="lg" variant="primary" type="submit">
                Send
              </Button>
            </Form>
            <br />
            <p>
              {" "}
              <h3>Thank you so much for your time!</h3>
              We hope to connect many audience members to your company.
            </p>
          </div>
        </section>
      </div>
    );
  }
}
