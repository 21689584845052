import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import Booking from "./containers/Booking";
import Amazon from "./containers/Amazon";
import AmazonLinks from "./containers/AmazonLinks";
import DataInput from "./containers/DataInput";

export default () =>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/amazon" element={<Amazon />} />
        <Route path="/amazon-links" element={<AmazonLinks />} />
        <Route path="/data-input" element={<DataInput />} />
        <Route element={<Home />} />
    </Routes>;
